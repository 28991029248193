audio::-webkit-media-controls-panel {
    background-color: transparent;
    color: red;
}
audio::-webkit-media-controls-enclosure {
    border-radius: 0;
    background-color: transparent;
}
audio::-webkit-media-controls-play-button {
    transform: scale(1.2, 1.2);
}